<template>
  <!-- 新增出库页面 -->
  <div class="warehouseStorage page-info-content">
    <el-form ref="ruleForm" :model="ruleForm" :rules="rules" :inline="true" label-position="left" label-width="130px" class="demo-ruleForm page-form form-item-row">
      <el-form-item label="出库单号">
        <el-input v-model="ruleForm.outNo" disabled placeholder="系统自动生成" />
      </el-form-item>
      <el-form-item label="仓库名称" prop="warehouseId">
        <el-select
          v-model="ruleForm.warehouseId"
          class="cmpNameSearch"
          filterable
          reserve-keyword
          placeholder="请选择"
          :remote-method="remoteMethod2"
          @change="changeData"
        >
          <el-option v-for="item in warehouseListArr" :key="item.id" :label="item.warehouseName" :value="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="货主名称" prop="custId">
        <el-select
          v-model="ruleForm.custId"
          class="cmpNameSearch"
          filterable
          reserve-keyword
          placeholder="请选择"
          :remote-method="remoteMethod"
          @change="changeData"
        >
          <el-option v-for="item in cmpNameArr" :key="item.cmpCode" :label="item.cmpName" :value="item.cmpCode" />
        </el-select>
      </el-form-item>
      <el-form-item label="出库时间" prop="outDate">
        <el-date-picker v-model="ruleForm.outDate" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptionsStart" type="datetime" placeholder="选择日期时间" />
      </el-form-item>
      <el-form-item label="司机姓名" prop="driverName">
        <el-input v-model="ruleForm.driverName" placeholder="请输入司机姓名" />
      </el-form-item>
      <el-form-item label="司机电话" prop="driverPhone">
        <el-input v-model="ruleForm.driverPhone" type="number" placeholder="请输入司机电话" />
      </el-form-item>
      <el-form-item label="车牌号" prop="carNo">
        <el-input v-model="ruleForm.carNo" placeholder="请输入车牌号" />
      </el-form-item>
      <el-form-item label="仓库出库员" prop="consignee">
        <el-input v-model="ruleForm.consignee" placeholder="请输入仓库出库员" />
      </el-form-item>
      <el-form-item label="运单号">
        <el-input v-model="ruleForm.waybillNo" placeholder="请输入运单号" />
      </el-form-item>
      <el-form-item label="下游客户名称">
        <el-input v-model="ruleForm.downCustName" placeholder="请输入下游客户名称" />
      </el-form-item>
      <el-form-item label="出库验证码" prop="checkCode">
        <el-input v-model="ruleForm.checkCode" placeholder="请输入出库验证码" />
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="ruleForm.remark" placeholder="请输入备注" />
      </el-form-item>
      <el-form-item class="lastOne" label="">
        <span class="title">出库货物明细</span><el-button plain size="small" @click="selectCargoLocationDetails">
          选择货位明细
        </el-button>
        <Table
          :item-data="itemData"
          :list-data="wmsInventoryIndetailList"
        >
          <!-- <template #invAmount="{ row }">
            <el-input @input="totalInv(row, 1)" v-model="row.invAmount" type="number" placeholder="请输入数字">
              </el-input>
          </template> -->
          <template #invWeight="{ row }">
            <el-input-number v-model="row.invWeight" :precision="2" :controls="false" type="number" placeholder="请输入数字" @change="totalInv(row, 2)" />
          </template>
        </Table>
      </el-form-item>
    </el-form>
    <div class="buttonCombination">
      <el-button size="small" :loading="$store.state.loading" type="primary" class="widen-button" @click="submitForm('ruleForm')">
        确定
      </el-button>
      <el-button size="small" class="widen-button" @click="resetForm('ruleForm')">
        重置
      </el-button>
    </div>
    <ChoiceInventory ref="choiceInventory" :params="ruleForm" @getInventory="getInventory" />
  </div>
</template>

<script>
import { getCmpBaseinfoList } from '@/api/memberManagement'
import ChoiceInventory from '../components/ChoiceInventory'
import Table from '@/components/Table'
import { rules } from '@/utils/matchFormRegex'
import { warehouseList, inventoryOut } from '@/api/warehousingSystem'
export default {
  components: { ChoiceInventory, Table },
  data() {
    return {
      ruleForm: {},
      pnid: '',
      warehouseListArr: [],
      cmpNameArr: [],
      pickerOptionsStart: {
        // 时间不能大于当前时间
        disabledDate: (time) => {
          return time.getTime() > Date.now()
        }
      },
      rules: {
        outDate: [{ required: true, message: '请选择出库时间', trigger: 'change' }],
        carNo: [...rules.carNo],
        waybillNo: [{ required: true, message: '请输入运单号', trigger: 'blur' }],
        driverName: [
          { required: true, message: '请输入司机姓名', trigger: 'blur' }
        ],
        consignee: [
          { required: true, message: '请输入提货人姓名', trigger: 'blur' }
        ],
        warehouseId: [{ required: true, message: '请输入选择一个仓库', trigger: 'change' }],
        custId: [{ required: true, message: '请输入选择一个货主', trigger: 'change' }],
        driverPhone: [
          { required: true, message: '请输入司机电话', trigger: 'blur' },
          { min: 10, max: 12, message: '长度应为11个字符', trigger: 'blur' }
        ]
      },
      wmsInventoryIndetailList: [], // 选择好出库的库存
      itemData: [
        // { label: '出库数量', slotName: 'invAmount' },
        { label: '出库数量(吨)', slotName: 'invWeight' },
        // { label: '库存数量', prop: 'detailInvAmount' },
        { label: '库存数量(吨)', prop: 'detailInvWeight' },
        { label: '品名', prop: 'detailGoodsName' },
        { label: '材质', prop: 'detailMaterials' },
        { label: '规格', prop: 'detailSpecs' },
        { label: '产地', prop: 'detailProductions' },
        { label: '钢卷号', prop: 'detailCoilNo' },
        { label: '库区编号', prop: 'goodsYardNo' },
        { label: '货位号', prop: 'detailLocationNo' }
      ],
      breadcrumbItem: [{ path: '/', name: '首页' }, { path: '/warehousingSystem/inventoryManagement', name: '库存管理' }, { path: '', name: '新增出库' }]
    }
  },
  mounted() {
    this.remoteMethod2('')
    this.remoteMethod()
  },
  methods: {
    // 当货主名称和仓库名改变时下面的表格置空
    changeData() {
      this.wmsInventoryIndetailList = []
    },
    // 当点击确定的时候把数据赋值上去
    getInventory(value) {
      this.wmsInventoryIndetailList = [...value]
    },
    // 联动搜搜货主列表
    remoteMethod(value) {
      getCmpBaseinfoList(value || '', res => {
        this.cmpNameArr = [...res.data]
      })
    },
    selectCargoLocationDetails() {
      if (!this.ruleForm.warehouseId || !this.ruleForm.custId) {
        this.$message.error('请先选择仓库名跟货主名！')
        return
      }
      this.$refs.choiceInventory.dialogTableVisible = true
    },
    // 联动搜搜仓库列表
    remoteMethod2(value) {
      warehouseList(value || '', res => {
        this.warehouseListArr = [...res.data]
      })
    },
    totalInv(item, index) {
      // 判断不能超过库存
      if (index === 1) {
        this.$set(item, 'invAmount', (item.detailInvAmount) < (item.invAmount) ? item.detailInvAmount : item.invAmount)
      } else if (index === 2) {
        this.$set(item, 'invWeight', (item.detailInvWeight) < (item.invWeight) ? item.detailInvWeight : item.invWeight)
      }
      let invAmount = 0; let invWeight = 0
      this.wmsInventoryIndetailList.forEach(item => {
        invAmount += (item.invAmount) || 0
        invWeight += (item.invWeight) || 0
      })
      this.ruleForm.currentOutAmount = invAmount
      this.ruleForm.cuttentOutWeight = invWeight
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 判断每个库存货位明细不为空
          this.ruleForm.wmsInventoryOutdetailReqList = []
          this.wmsInventoryIndetailList.forEach(item => {
            if (item.invWeight) {
              this.ruleForm.wmsInventoryOutdetailReqList.push({
                detailId: item.detailId,
                invWeight: (item.invWeight) || 0,
                inventoryId: item.detailInventoryId,
                downCustName: item.downCustName
              })
            }
          })
          if (this.ruleForm.wmsInventoryOutdetailReqList.length === 0) {
            this.$message.error('请最少填写一个出库货位明细！')
            return
          }
          // 获取货主名称
          this.cmpNameArr.forEach(item => {
            if (item.cmpCode === this.ruleForm.custId) { this.ruleForm.custName = item.cmpName }
          })
          // 获取仓库编码
          this.warehouseListArr.forEach(item => {
            if (item.id === this.ruleForm.warehouseId) { this.ruleForm.warehouseName = item.warehouseName }
          })
          inventoryOut(this.ruleForm, () => {
            this.$message.success('出库成功！')
            setTimeout(() => {
              this.$router.go(-1)
            }, 1000)
          })
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    }
  }

}
</script>

<style lang="scss">
.warehouseStorage .el-form {

  .link {
    color: rgb(64, 158, 255);
    text-decoration: underline;
    cursor: pointer;
  }
}

.warehouseStorage .buttonCombination {
  width: 100%;
  text-align: center;
}

.warehouseStorage .el-form .lastOne {
  width: 100%;
  .title{
    color: #333;
    font-weight: 700;
    float: left;
  }

  .el-form-item__label {
    color: #333;
    font-weight: 700;
  }

  .el-form-item__content {
    text-align: center;
    padding: 0 32px;
    width: calc(100% - 64px);
    overflow-y: auto;
  }
}

.warehouseStorage .el-form .el-input__inner,
.warehouseStorage .el-form .el-select,
.warehouseStorage .el-form .el-input,
.warehouseStorage .el-form .el-cascader {
  width: 100%;
}

.warehouseStorage .table {
  width: 100%;
  margin-top: 12px;
  border-collapse: collapse;
}

.warehouseStorage .table td {
  border: 1px solid #ededed;
  text-align: center;
}

.warehouseStorage .table td .el-input {
  width: 160px;
}

.warehouseStorage .table td i {
  font-size: 26px;
  cursor: pointer;
  line-height: 40px;
}

.warehouseStorage .table td .el-input .el-input__inner {
  border: 0;
  text-align: center;
}
</style>
